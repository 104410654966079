import React from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectView = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const userAgent = window.navigator.userAgent;
        if (/Android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.creativespark.org'
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            navigate('/localeyes/redirect/ios');
        } else {
            navigate('/localeyes/redirect/desktop');
        }
    }, [navigate]);

    return null;
}

export default RedirectView;