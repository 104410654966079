import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDH7GVSHwvsX3YEP4kVnPDHcmfoeAMm3Mc",
    authDomain: "localeyes-fc5e4.firebaseapp.com",
    databaseURL: "https://localeyes-fc5e4-default-rtdb.firebaseio.com",
    projectId: "localeyes-fc5e4",
    storageBucket: "localeyes-fc5e4.appspot.com",
    messagingSenderId: "375218736956",
    appId: "1:375218736956:web:02fe8fb773809acb2f9839",
    measurementId: "G-CV1CSJTLHR"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);