import React from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Input, Link, Listbox, ListboxItem, Divider, Tabs, Tab, Card, CardBody, Pagination, Select, SelectItem } from "@nextui-org/react";

import { auth, db } from "../firebaseConfig.tsx";

const SignUpModal = () => {
    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const [orgName, setOrgName] = React.useState(null);
    const [orgEmail, setOrgEmail] = React.useState(null);
    const [orgTel, setOrgTel] = React.useState(null);
    const [orgAddress, setOrgAddress] = React.useState(null);
    const [orgType, setOrgType] = React.useState(new Set([]));

    const [, update] = React.useState(null);
    const [errorCode, setErrorCode] = React.useState(null);
    const [currentIndex, setCurrentIndex] = React.useState(1);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const navigate = useNavigate();
    const orgTypes = [
        {
            name: "For Profit",
        },
        {
            name: "Non Profit",
        },
        {
            name: "Chamber of Commerce",
        },
        {
            name: "School",
        },
        {
            name: "Government",
        },
    ]

    React.useEffect(() => {
        setErrorCode(null);
    }, [email, password]);

    const signUp = async (event) => {
        createUserWithEmailAndPassword(auth, email, password).then(async (user) => {
            console.log(orgAddress, orgEmail, orgName, orgTel, orgType)
            await setDoc(doc(db, "users", user.user.uid), {
                address: orgAddress,
                email: orgEmail,
                isVerified: false,
                name: orgName,
                tel: orgTel,
                type: orgType.currentKey,
            }).then(() => {
                console.log("Document successfully written!");
                update();
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                setErrorCode(errorMessage);
            });

        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            switch (errorCode) {
                case "auth/email-already-in-use":
                    setErrorCode("Account email is already in use.");
                    break;
                default:
                    setErrorCode(errorMessage);
                    break;
            }
        });
    };

    return (
        <>
            <Button onClick={onOpen} color="primary" variant="solid">Sign Up</Button>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="center"
                className="w-[500px]"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col text-foreground">Sign Up</ModalHeader>
                            <ModalBody>
                                <div className="flex justify-center">
                                    <Pagination variant="solid" total={2} initialPage={1} page={currentIndex} onChange={setCurrentIndex} />
                                </div>

                                {currentIndex === 1 &&
                                    <>
                                        <Input
                                            autoFocus
                                            isRequired
                                            label="Email"
                                            variant="bordered"
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
                                        <Input
                                            isRequired
                                            label="Password"
                                            type="password"
                                            variant="bordered"
                                            onChange={(event) => setPassword(event.target.value)}
                                        />
                                    </>}

                                {currentIndex === 2 &&
                                    <>
                                        <Input
                                            isRequired
                                            label="Name of Organization"
                                            type="text"
                                            variant="bordered"
                                            onChange={(event) => setOrgName(event.target.value)}
                                        />
                                        <Input
                                            isRequired
                                            label="Organization Email"
                                            type="email"
                                            variant="bordered"
                                            onChange={(event) => setOrgEmail(event.target.value)}
                                        />
                                        <Input
                                            label="Organization Phone Number"
                                            type="tel"
                                            variant="bordered"
                                            onChange={(event) => setOrgTel(event.target.value)}
                                        />
                                        <Input
                                            label="Organization Address"
                                            type="text"
                                            variant="bordered"
                                            onChange={(event) => setOrgAddress(event.target.value)}
                                        />
                                        <div className="">
                                            <Select selectedKeys={orgType} onSelectionChange={setOrgType} items={orgTypes} label="Type of Organization" placeholder="Select a type">
                                                {(item) => <SelectItem key={item.name}>{item.name}</SelectItem>}
                                            </Select>
                                        </div>
                                    </>}

                                {errorCode &&
                                    <div className="text-red-500 text-sm font-semibold">{errorCode}</div>
                                }
                                <div className="flex justify-end gap-x-4">
                                    <Button color="danger" variant="bordered" onPress={onClose}>
                                        Close
                                    </Button>

                                    {currentIndex === 2 ?
                                        <Button color="primary" variant="solid" onPress={() => signUp()}>
                                            Sign Up
                                        </Button>
                                        :
                                        <Button color="primary" variant="solid" onPress={() => setCurrentIndex(currentIndex + 1)}>
                                            Next
                                        </Button>
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter className="flex justify-start bg-slate-200">
                                <p className="text-sm text-gray-500">By signing up you agree to our {" "}
                                    <Link href="/terms-and-privacy" color="" className="text-sm text-blue-600">
                                        Terms & Privacy
                                    </Link></p>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent >
            </Modal >
        </>
    );
};

export default SignUpModal;