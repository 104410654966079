import React from "react";
import { Card, CardHeader, CardBody, Image, CardFooter, Button, Link, Divider, Input, Select, SelectItem, Accordion, AccordionItem } from "@nextui-org/react";

// import { CurrentPageContext } from "../../App";

const HomeView = () => {
    // const currentPage = React.useContext(CurrentPageContext);

    // React.useEffect(() => {
    //     currentPage.setCurrentPage("home");
    // });

    return (
        <div className="justify-center items-center flex-col flex overflow-x-hidden px-10">
            <section id="home" className="flex items-center flex-col pt-20 gap-0 sm:flex-row sm:pt-0 sm:gap-20 sm:h-[100svh]">
                <div className="flex flex-col items-center text-center gap-10 sm:items-start sm:text-start">
                    <div className="justify-center items-center h-[50px] sm:flex sm:h-auto sm:w-[600px]">
                        <img className="sm:-translate-x-5" alt="logo" src={require("../../images/image.png")} />
                    </div>

                    <h1 className="text-[2.5rem] lg:text-5xl font-semibold">
                        Get involved, get
                        <span className="inline font-semibold from-primary to-secondary text-[2.5rem] lg:text-5xl bg-clip-text text-transparent bg-gradient-to-r">
                            {" connected"}
                        </span>
                        .
                    </h1>


                    <div className="flex flex-col gap-5 sm:flex-row">
                        <Button
                            variant="solid"
                            href="/localeyes/redirect"
                            size="lg"
                            color="primary"
                            as={Link}
                        >
                            Download Now
                        </Button>
                        <Button
                            variant="ghost"
                            size="lg"
                            as={Link}
                            href="mailto:paperlitesoftware@gmail.com"
                        >
                            Contact Us
                        </Button>
                    </div>
                </div>

                {/* <Card shadow="sm" className="bg-dp02" isPressable onPress={() => console.log("item pressed")}>
                    <CardBody className="p-0">
                        <Image
                            shadow="sm"
                            radius="lg"
                            alt={"dashboard preview"}
                            src="https://via.placeholder.com/500x300"
                        />
                    </CardBody>
                    <CardFooter className="flex flex-col items-start">
                        <h4 className="font-bold text-large">Dashboard 1</h4>
                        <small className="text-default-500">Hey this is an awesome dashboard! Check this out! It's great!</small>
                    </CardFooter>
                </Card> */}

                <img alt="app preview" className="invisible h-[0px] w-[250px] sm:visible sm:h-auto" src={require("../../images/phone-example.png")} />
            </section>

            <section id="features" className="pt-20 flex flex-col justify-center items-center gap-y-10 sm:grid sm:pt-20 sm:grid-cols-2 sm:place-items-center sm:gap-x-20 sm:gap-y-20">
                <div className="text-center sm:text-start">
                    <h1 className="text-[2.5rem] lg:text-5xl font-semibold">
                        <span className="inline font-semibold from-primary to-secondary text-[2.5rem] lg:text-5xl bg-clip-text text-transparent bg-gradient-to-r">
                            {"AI powered "}
                        </span>
                        <br />
                        content creation.
                    </h1>
                    <p className="text-lg text-foreground-400">Easily produce content for your organization <br />utilizing modern artificial intelligence.</p>
                    <Button size="lg" variant="ghost" className="mt-5">Learn More</Button>
                </div>
                <img alt="ai + localeyes" src={require("../../images/ai.png")} />

                <img alt="placeholder" className="hidden sm:flex" src={require("../../images/social-media.png")} />
                <div className="text-center sm:text-start">
                    <h1 className="text-[2.5rem] lg:text-5xl font-semibold">
                        <span className="inline font-semibold from-primary to-secondary text-[2.5rem] lg:text-5xl bg-clip-text text-transparent bg-gradient-to-r">
                            {"Effortless "}
                        </span>
                        social <br /> media presence.
                    </h1>
                    <p className="text-lg text-foreground-400">Publish your events to all social media platforms<br /> at the push of a button.</p>
                    <Button size="lg" variant="ghost" className="mt-5">Learn More</Button>
                </div>
                <img alt="placeholder" className="flex sm:hidden" src={require("../../images/social-media.png")} />

                <div className="text-center sm:text-start">
                    <h1 className="text-[2.5rem] lg:text-5xl font-semibold">
                        <span className="inline font-semibold from-primary to-secondary text-[2.5rem] lg:text-5xl bg-clip-text text-transparent bg-gradient-to-r">
                            {"Indepth "}
                        </span>
                        analytics.
                    </h1>
                    <p className="text-lg text-foreground-400">Harness the information around you with a <br />full-feature analytical suite.</p>
                    <Button size="lg" variant="ghost" className="mt-5">Learn More</Button>
                </div>
                <img alt="placeholder" src={require("../../images/dashboard.png")} />
            </section>

            {/* <section id="pricing" className="h-[100svh] flex flex-col items-center gap-y-10">
                <h1 className="text-5xl font-semibold">Estimated pricing.</h1>

                <h2 className="text-4xl">What's the population of your city?</h2>
                <Input label="Population" type="number" />

                <h2 className="text-4xl">What type of organization are you?</h2>
                <Select label="Type of Organization" placeholder="Select a type">
                    <SelectItem>For Profit</SelectItem>
                    <SelectItem>Non Profit</SelectItem>
                    <SelectItem>Chamber of Commerce</SelectItem>
                    <SelectItem>School</SelectItem>
                    <SelectItem>Government</SelectItem>
                </Select>

                <h2 className="text-4xl">How many employees do you have?</h2>
                <Input label="Number of Employees" type="number" />

                <h2 className="text-4xl">How many members do you have?</h2>
                <Input label="Number of Members" type="number" />

                <h2 className="text-4xl">How many students do you have?</h2>
                <Input label="Number of Students" type="number" />

                <h2 className="text-4xl">Choose a plan.</h2>
                <div className="flex gap-10">
                    <Card className="bg-dp01">
                        <CardHeader>Starter</CardHeader>
                        <CardBody className="flex flex-col gap-4">
                            <Accordion variant="bordered">
                                <AccordionItem startContent={<CheckIcon color="#17c964" />} title="Feature 1">
                                    stuff
                                </AccordionItem>
                                <AccordionItem startContent={<MinusIcon color="#f5a524" />} title="Event Management">
                                    stuff
                                </AccordionItem>
                                <AccordionItem startContent={<XIcon color="#f31260" />} title="AI Powered Content Creation">
                                    stuff
                                </AccordionItem>
                                <AccordionItem startContent={<XIcon color="#f31260" />} title="Analytical Suite">
                                    stuff
                                </AccordionItem>
                            </Accordion>

                        </CardBody>
                        <CardFooter>Footer</CardFooter>
                    </Card>
                </div>
            </section >

            <section id="contact" className="h-[100svh] flex items-center">
                contact
            </section> */}

            {/* <section className="h-[100svh] flex items-center flex-col justify-center gap-y-10">
                <div className="justify-center items-center inline-flex">
                    <div className="flex flex-col justify-start items-center gap-2">
                        <h1 className="font-bold text-4xl">{"Meet the Team"}</h1>
                        <p className="">{ }</p>
                    </div>
                </div>

                <div className="flex gap-8 flex-wrap justify-center">
                    <Card className="col-span-12 sm:col-span-4 h-[300px]" isFooterBlurred>
                        <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                            <p className="text-tiny text-white/60 uppercase font-bold">Co-founder</p>
                            <h4 className="text-white font-medium text-large">Johnathan Stevers</h4>
                        </CardHeader>
                        <Image
                            isBlurred
                            alt="Woman listing to music"
                            removeWrapper
                            className="z-0 w-full h-full object-cover"
                            height={200}
                            src="https://via.placeholder.com/400x500"
                            width={200}
                        />
                        <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                            <p className="text-tiny text-white/80">Developer</p>
                        </CardFooter>
                    </Card>

                    <Card className="col-span-12 sm:col-span-4 h-[300px]" isFooterBlurred>
                        <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                            <p className="text-tiny text-white/60 uppercase font-bold">Co-founder</p>
                            <h4 className="text-white font-medium text-large">Cayden Knight</h4>
                        </CardHeader>
                        <Image
                            isBlurred
                            alt="Woman listing to music"
                            removeWrapper
                            className="z-0 w-full h-full object-cover"
                            height={200}
                            src="https://via.placeholder.com/400x500"
                            width={200}
                        />
                        <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                            <p className="text-tiny text-white/80">Developer</p>
                        </CardFooter>
                    </Card>

                    <Card className="col-span-12 sm:col-span-4 h-[300px]" isFooterBlurred>
                        <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                            <p className="text-tiny text-white/60 uppercase font-bold">Co-founder</p>
                            <h4 className="text-white font-medium text-large">Faith Buford</h4>
                        </CardHeader>
                        <Image
                            isBlurred
                            alt="Woman listing to music"
                            removeWrapper
                            className="z-0 w-full h-full object-cover"
                            height={200}
                            src="https://via.placeholder.com/400x500"
                            width={200}
                        />
                        <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                            <p className="text-tiny text-white/80">Outreach Coordinator</p>
                        </CardFooter>
                    </Card>
                </div>
            </section> */}
        </div >
    )
}

export default HomeView;