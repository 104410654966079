import React from "react"

import { CurrentPageContext } from "../../App.tsx";

const TosView = () => {
    const currentPage = React.useContext(CurrentPageContext);
    React.useEffect(() => {
        currentPage.setCurrentPage("tos");
    });

    return (
        <div className="pt-16 flex flex-col">
            <h1 className="font-bold text-4xl">LocalEyes Terms of Service</h1>

            <p>Last updated: 10/20/2023</p>

            <p>Welcome to LocalEyes! These Terms of Service (the "Terms") are a legally binding agreement between you and LocalEyes (referred to as "LocalEyes," "we," "us," or "our"). By using the LocalEyes app (the "App"), you agree to abide by these Terms. If you do not agree with these Terms, please do not use the App.</p>

            <h2 className="font-bold text-2xl">1. Acceptance of Terms</h2>

            <p>By using the App, you agree to comply with these Terms and all applicable laws and regulations.</p>
            <p>You represent that you are at least 18 years old or have reached the age of majority in your jurisdiction.</p>

            <h2 className="font-bold text-2xl">2. Privacy</h2>

            <p>Your use of the App is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review our Privacy Policy to understand how your data is handled.</p>

            <h2 className="font-bold text-2xl">3. User Conduct</h2>

            <p>When using the App, you agree to:</p>

            <p>Provide accurate and up-to-date information.</p>
            <p>Respect the rights and privacy of other users.</p>
            <p>Not engage in illegal activities, harass, or engage in harmful behavior.</p>
            <p>Not use the App for commercial purposes without our express consent.</p>
            <p>Not distribute spam, unsolicited emails, or engage in any other form of unauthorized advertising or solicitation.</p>

            <h2 className="font-bold text-2xl">4. Intellectual Property</h2>

            <p>LocalEyes owns all the intellectual property rights related to the App, including but not limited to trademarks, copyrights, and patents. You may not reproduce, modify, or distribute our content without our written permission.</p>

            <h2 className="font-bold text-2xl">5. Termination</h2>

            <p>We reserve the right to terminate or suspend your account and access to the App at our discretion, without prior notice, if you violate these Terms.</p>

            <h2 className="font-bold text-2xl">6. Changes to Terms</h2>

            <p>LocalEyes may update these Terms periodically, so please review them regularly. Your continued use of the App after changes constitutes your acceptance of the revised Terms.</p>

            <h2 className="font-bold text-2xl">7. Limitation of Liability</h2>

            <p>LocalEyes is not liable for any direct or indirect damages, including but not limited to loss of data or profits, arising from your use or inability to use the App.</p>

            <h2 className="font-bold text-2xl">8. Governing Law</h2>

            <p>These Terms are governed by and construed in accordance with the laws of the state of Oklahoma, United States.</p>

            <h2 className="font-bold text-2xl">9. Contact Information</h2>

            <p>If you have any questions or concerns regarding these Terms, please contact us at paperlitesoftware@gmail.com.</p>
            <h1 className="font-bold text-4xl">LocalEyes Privacy Policy</h1>
            <p>Last updated: 10/16/2023</p>
            <p>LocalEyes ("we," "us," "our") values your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use the LocalEyes app (the "App"). By using the App, you agree to the terms of this Privacy Policy.</p>
            <h2 className="font-bold text-2xl">Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <p>Personal Information: This includes your name, email address, and phone number, which may be required for account creation and app usage.</p>
            <p>Usage Information: We collect data about your interaction with the App, including the features you use and how you use them.</p>
            <p>Device Information: We may collect information about your device, such as your device type, operating system, and unique device identifiers.</p>
            <p>Location Information: If you enable location services, we may collect your precise location data to provide location-based services.</p>
            <h2 className="font-bold text-2xl">How We Use Your Information</h2>
            <p>We may use your information for the following purposes:</p>
            <p>To provide, maintain, and improve the App.</p>
            <p>To personalize your user experience, including content and recommendations.</p>
            <p>To communicate with you about the App, such as updates and support.</p>
            <p>To respond to your requests and inquiries.</p>
            <p>To enforce our Terms of Service.</p>
            <h2 className="font-bold text-2xl">Data Sharing and Disclosure</h2>
            <p>We may share your information with:</p>
            <p>Service Providers: We may engage third-party service providers to assist in app development, maintenance, and related services.</p>
            <p>Legal Compliance: We may share your information if required by law or in response to a legal request.</p>
            <p>Business Transfers: In the event of a merger, sale, or transfer of assets, your information may be part of the transferred assets.</p>
            <h2 className="font-bold text-2xl">Your Choices</h2>
            <p>You have the following choices regarding your data:</p>
            <p>Account Information: You can review and update your account information by logging into your account settings.</p>
            <p>Location Information: You can enable or disable location services through your device settings.</p>
            <h2 className="font-bold text-2xl">Security</h2>
            <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>
            <p>Changes to this Privacy Policy</p>
            <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes through the App or by other means.</p>
            <h2 className="font-bold text-2xl">Contact Us</h2>
            <p>If you have questions or concerns about this Privacy Policy or our data practices, please contact us at paperlitesoftware@gmail.com.</p>
        </div>
    );
};

export default TosView;