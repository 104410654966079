import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NextUIProvider } from "@nextui-org/system";
import { ThemeProvider as NextThemesProvider } from "next-themes";

import HomeView from "./components/views/homeView.tsx";
import SettingsView from "./components/views/settingsView.tsx";
import ProfileView from "./components/views/profileView.tsx";
import FeaturesView from "./components/views/featuresView.tsx";
import PricingView from "./components/views/pricingView.tsx";
import DashboardView from "./components/views/dashboardView.tsx";
import TosView from "./components/views/tosView.tsx";
import NavbarWrapper from "./components/navbarWrapper";
import Footer from "./components/footer";
import RedirectView from "./components/views/redirectView.tsx";
import ComingSoonView from "./components/views/comingSoonView.tsx";
import DesktopView from "./components/views/desktopView.tsx";

export const CurrentPageContext = React.createContext<{ currentPage: string; setCurrentPage: React.Dispatch<React.SetStateAction<string>> }>({ currentPage: "", setCurrentPage: () => { } });

function Providers({ children }) {
  const [currentPage, setCurrentPage] = React.useState("");

  return (
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="light">
        <CurrentPageContext.Provider value={{ currentPage, setCurrentPage }}>
          <Router>
            {children}
          </Router>
        </CurrentPageContext.Provider>
      </NextThemesProvider>
    </NextUIProvider>
  );
}

function App() {
  return (
    <Providers>
      <main className="text-foreground flex flex-col min-h-[100svh] overflow-x-hidden">
        <NavbarWrapper />
        <Routes>
          <Route path="*" element={"404 Not Found"} />
          <Route path="/" element={<HomeView />} />
          <Route path="/localeyes/redirect" element={<RedirectView />} />
          <Route path="/profile" element={<ComingSoonView />} />
          <Route path="/settings" element={<ComingSoonView />} />
          <Route path="/pricing" element={<ComingSoonView />} />
          <Route path="/features" element={<ComingSoonView />} />
          <Route path="/dashboard" element={<ComingSoonView />} />
          <Route path="/support" element={<ComingSoonView />} />
          <Route path="/localeyes/redirect/ios" element={
            <ComingSoonView>
              for IOS
            </ComingSoonView>}
          />
          <Route path="/localeyes/redirect/desktop" element={<DesktopView />} />
          <Route path="/terms-and-privacy" element={<TosView />} />
        </Routes>
        <Footer />
      </main>
    </Providers>
  );
};

export default App;