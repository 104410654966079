import React from "react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Avatar, User } from "@nextui-org/react";

import { auth } from "../firebaseConfig.tsx";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ProfileIcon } from "../icons/user.svg";
import { ReactComponent as DashboardIcon } from "../icons/layout.svg";
import { ReactComponent as SettingsIcon } from "../icons/settings.svg";
import { ReactComponent as SupportIcon } from "../icons/help-circle.svg";
import { ReactComponent as LogOutIcon } from "../icons/log-out.svg";

const AvatarWrapper = () => {
    const navigate = useNavigate();

    return (
        <div className="flex items-center gap-4">
            <Dropdown placement="bottom-end">
                <DropdownTrigger>
                    <Avatar
                        as="button"
                        className="transition-transform"
                        src={auth.currentUser.photoURL}
                    />
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="solid"
                    onAction={(key) => {
                        switch (key) {
                            case "logout":
                                auth.signOut();
                                navigate("/");
                                break;
                            case "dashboard":
                                navigate("/dashboard")
                                break;
                            case "profile":
                                navigate("/profile")
                                break;
                            case "settings":
                                navigate("/settings")
                                break;
                            case "support":
                                navigate("/support")
                                break;
                            default:
                                break;
                        }
                    }}>
                    <DropdownItem startContent={<ProfileIcon />} key="profile">
                        <p className="font-semibold">Signed in as</p>
                        <p className="font-semibold">{auth.currentUser.email}</p>
                    </DropdownItem>
                    <DropdownItem startContent={<DashboardIcon />} key="dashboard">
                        Dashboard
                    </DropdownItem>
                    <DropdownItem startContent={<SupportIcon />} key="support">
                        Help & Feedback
                    </DropdownItem>
                    <DropdownItem startContent={<SettingsIcon />} key="settings">
                        Settings
                    </DropdownItem>
                    <DropdownItem startContent={<LogOutIcon />} key="logout" color="danger">
                        Log Out
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div >
    );
}

export default AvatarWrapper;