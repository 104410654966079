import React from "react";

const DesktopView = () => {
    return (
        <div className="flex flex-col justify-center items-center text-center gap-y-5 pt-32">
            <h1 className="text-4xl sm:text-6xl font-semibold">Only avaliable on Android and iOS.</h1>
        </div>
    );
};

export default DesktopView;