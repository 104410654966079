import { Divider, Link } from "@nextui-org/react";
import React from "react";

const Footer = () => {
    return (
        <div className="mt-auto pt-20">
            <Divider />
            <div className="w-[100svw] p-10 bg-none dark:shadow-none dark:bg-dp01 flex flex-col justify-center items-start gap-y-5 sm:gap-x-20 sm:flex-row sm:gap-y-0">
                <div className="flex flex-col sm:gap-y-5">
                    <h1>For Users</h1>
                    <Link href="/localeyes/redirect">Download the app</Link>
                </div>
                <div className="flex flex-col sm:gap-y-5">
                    <h1>For Businesses</h1>
                    <Link href="/dashboard">Dashboard</Link>
                </div>
                {/* <div className="flex flex-col gap-y-5">
                    <h1>Account</h1>
                    <Link href="/#">Remove Data</Link>
                </div> */}
                <div className="flex flex-col sm:gap-y-5">
                    <h1>Contact Us</h1>
                    <Link className="break-words overflow-x-hidden" href="mailto:paperlitesoftware@gmail.com">paperlitesoftware@gmail.com</Link>
                </div>

            </div>
            <p className="text-foreground-400 text-center">Localeyes™ 2023</p>
        </div>
    )
};

export default Footer;