import React from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Input, Link } from "@nextui-org/react";

import { auth } from "../firebaseConfig.tsx";

const LoginModal = () => {
    const [, update] = React.useState(null);
    const [errorCode, setErrorCode] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const navigate = useNavigate();

    React.useEffect(() => {
        setErrorCode(null);
    }, [email, password]);

    const signIn = async (event) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
                onOpenChange(false);
                update();
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                switch (errorCode) {
                    case "auth/invalid-login-credentials":
                        setErrorCode("Email or password is incorrect.");
                        break;
                    default:
                        setErrorCode(errorMessage);
                        break;
                }
            });
    };

    return (
        <>
            <Link onClick={onOpen} color="primary" className="cursor-pointer">Login</Link>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="text-foreground flex flex-col gap-1">Login</ModalHeader>
                            <ModalBody>
                                <Input
                                    autoFocus
                                    label="Email"
                                    variant="bordered"
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                                <Input
                                    label="Password"
                                    type="password"
                                    variant="bordered"
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                {errorCode &&
                                    <div className="text-red-500 text-sm font-semibold">{errorCode}</div>
                                }
                                <div className="flex py-2 px-1 justify-between">
                                    <Link onClick={() => navigate("/")} color="primary" size="sm">
                                        Forgot password?
                                    </Link>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="bordered" onPress={onClose}>
                                    Close
                                </Button>
                                <Button color="primary" variant="solid" onPress={signIn}>
                                    Sign in
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default LoginModal;