import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, Switch } from "@nextui-org/react";
import { useTheme } from "next-themes";

import LoginModal from "./loginModal";
import AvatarWrapper from "./avatarWrapper";
import { auth } from "../firebaseConfig.tsx";
import SignUpModal from "./signUpModal";
import { CurrentPageContext } from "../App.tsx";
import { ReactComponent as DarkModeIcon } from "../icons/moon.svg";
import { ReactComponent as LightModeIcon } from "../icons/sun.svg";

const NavbarWrapper = () => {
    const currentPage = React.useContext(CurrentPageContext);
    const [, setUser] = React.useState(null);

    const navigate = useNavigate();
    const { theme, setTheme } = useTheme()

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return unsubscribe;
    }, []);

    return (
        <>
            <Navbar className="fixed shadow-md bg-opacity-0 dark:shadow-none dark:bg-dp02 dark:bg-opacity-50" classNames={{
                item: [
                    "flex",
                    "relative",
                    "h-full",
                    "items-center",
                    "data-[active=true]:after:content-['']",
                    "data-[active=true]:after:absolute",
                    "data-[active=true]:after:translate-y-4",
                    "data-[active=true]:after:left-0",
                    "data-[active=true]:after:right-0",
                    "data-[active=true]:after:h-[2px]",
                    "data-[active=true]:after:rounded-[2px]",
                    "data-[active=true]:after:bg-primary",
                ]
            }}>
                <NavbarBrand>
                    <img alt="logo" className="w-[50px] h-[50px]" src={require("../images/logo.png")} />
                    <p className="font-bold text-inherit">LocalEyes</p>
                </NavbarBrand>
                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem isActive={currentPage.currentPage === "home"}>
                        <Link href="/#" onClick={() => { navigate("/#"); currentPage.setCurrentPage("home") }} color="foreground">
                            Home
                        </Link>
                    </NavbarItem>
                    <NavbarItem isActive={currentPage.currentPage === "features"}>
                        <Link href="/#features" onClick={() => { navigate("/#features"); currentPage.setCurrentPage("features") }} color="foreground">
                            Features
                        </Link>
                    </NavbarItem>
                    {/* <NavbarItem isActive={currentPage.currentPage === "pricing"}>
                        <Link href="/#pricing" onClick={() => { navigate("/#pricing"); currentPage.setCurrentPage("pricing") }} color="foreground">
                            Pricing
                        </Link>
                    </NavbarItem>
                    <NavbarItem isActive={currentPage.currentPage === "contact"}>
                        <Link href="/#contact" onClick={() => { navigate("/#contact"); currentPage.setCurrentPage("contact") }} color="foreground">
                            Contact
                        </Link>
                    </NavbarItem> */}
                </NavbarContent>
                <NavbarContent justify="end">
                    {/* <Switch
                        defaultSelected
                        onValueChange={(value) => setTheme(value ? 'dark' : 'light')}
                        size="lg"
                        color="primary"
                        startContent={<DarkModeIcon />}
                        endContent={<LightModeIcon />}
                    >
                    </Switch> */}
                    {
                        auth.currentUser ?
                            <NavbarItem>
                                <AvatarWrapper />
                            </NavbarItem>
                            :
                            <>
                                <NavbarItem className="flex">
                                    <LoginModal />
                                </NavbarItem>
                                <NavbarItem>
                                    <SignUpModal />
                                </NavbarItem>
                            </>
                    }
                </NavbarContent>
            </Navbar >
        </>
    );
}

export default NavbarWrapper;
