import React from "react";

type ComingSoonViewProps = {
    children?: React.ReactNode;
};

const ComingSoonView = (props: ComingSoonViewProps) => {
    return (
        <div className="flex flex-col justify-center items-center text-center gap-y-5 pt-32">
            <h1 className="text-4xl sm:text-6xl font-semibold">Sorry... this is currently under construction.</h1>
            <h2 className="text-4xl sm:text-5xl">Come back later {props.children}!</h2>
        </div>
    );
};

export default ComingSoonView;